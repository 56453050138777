.App {
	width: 100%;
	height: 100%;
 }
 #root, .fs {
	 width: 100%;
	 height: 100%;
 }
 
 .spinner {
	 width: 25px;
	 height: 25px;
 }
 .error_msg {
	 justify-content: center;
	 display: flex;
	 margin: 5px;
	 color: rgb(247, 34, 34);
	 font-weight: 500;
 }
 
 .fade {
	 animation: fade-in-keyframes 0.5s linear;
 }
 @keyframes fade-in-keyframes {
	 from {opacity: 0; height:0em}
	 to {opacity: 1;height:1em}
 }
 
 .closeWidth {
	 flex-basis:0px;
	 width: 5px;
	 animation: fade-to0-width 0.3s ease-in-out;
 }
 @keyframes fade-to0-width {
	 from {flex-basis:200px; width: 200px}
	 to {flex-basis:0px; width: 0px}
 }
 
 .openWidth {
	 flex-basis:250px;
	 animation: fade-to200-width 0.3s ease-in-out;
 }
 @keyframes fade-to200-width {
	 from {flex-basis:0px; width: 0px}
	 to {flex-basis:250px; width: 250px}
 }
 
 