h1 {
	text-align: center;
	font-weight: 200;
}

.sendBtn {
	padding: 8px;
    margin: 6px;
    border-radius: 10px;
    font-size: 1em;
    margin-top: 10px;
    border-style: outset;
    background: #baea94;
    font-weight: 600;
    border-width: 2px;
    color: rgb(1, 25, 41);
    font-family: 'IBM Plex Sans', sans-serif;
}

.resetBtn {
	padding: 8px;
    margin: 6px;
    border-radius: 10px;
    font-size: 1em;
    margin-top: 10px;
    border-style: outset;
    background: aliceblue;
    font-weight: 600;
    border-width: 2px;
    color: rgb(1, 25, 41);
    font-family: 'IBM Plex Sans', sans-serif;
}

.cc {
	padding: 10px;
	font-size: 1.2em;
	width: 100px;
}
.cn {
	padding: 10px;
	font-size: 1.2em;
	width: 100px;
}

.campos {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.campos>label {
	padding:5px;
}
.campos>label>input {
	margin-left: 5px;
	border-radius: 4px;
	padding:5px;
}