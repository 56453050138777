

.f {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;	
	background-image: url("/img/bg.jpg");
	position: absolute !important;
}

.login_box {
	display: flex;
	flex-direction: column;
	z-index: 100;
	min-width: 500px;
	padding: 10px;
	border-radius: 20px;
	box-shadow: 0px 0px 7px 2px #b7b7bb;
	background-color: rgba(218, 218, 218, 0.78);
	justify-content: center;
	align-items: center;
}

.login_fields {
	margin-top:10px;
}

#logo {
	width: 90%;
	max-width: 500px;
	padding:10px;
}

.fieldname {
	font-weight: 600;
	padding-right: 20px;
}

.login_input {
	border-radius: 8px;
	border-width: 1px;
	border-style: inset;
	background: rgb(246, 255, 255);
	padding-top:5px;
	padding-bottom: 3px;
	padding-left:10px;
	font-family: "Lucida Console", Monaco, monospace	;

}

.field_line {
	padding:3px;
	justify-content: space-between;
	display:flex;
}

.login_button {
	flex: 1 1;
	padding: 8px;
	margin: 6px;
	border-radius: 10px;
	font-size: 1em;
	margin-top: 10px;
	border-style: outset;
	background: #baea94;	
	font-weight: 600;
	border-width: 2px;
	color: rgb(1, 25, 41);
	font-family: 'IBM Plex Sans', sans-serif;
}

.title {
	font-family: 'IBM Plex Sans', sans-serif;
	font-size: 1.4em;
	font-weight: 600;
	color: hsl(206, 59%, 42%);
	margin-bottom: 10px;
}