@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.transitionFadeIn-appear {
	opacity: 0.10;
}
.transitionFadeIn-appear.transitionFadeIn-appear-active {
  opacity: 1;
  -webkit-transition: opacity 1s ease-in;
  transition: opacity 1s ease-in;
}

.transitionFadeEnterLeave-enter {
	opacity: 0;
}
.transitionFadeEnterLeave-enter.transitionFadeEnterLeave-enter-active {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease-in 0.25s;
  transition: opacity 0.25s ease-in 0.25s;
}

.transitionFadeEnterLeave-leave {
	opacity: 1;
}
.transitionFadeEnterLeave-leave.transitionFadeEnterLeave-leave-active {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}
.App {
	width: 100%;
	height: 100%;
 }
 #root, .fs {
	 width: 100%;
	 height: 100%;
 }
 
 .spinner {
	 width: 25px;
	 height: 25px;
 }
 .error_msg {
	 justify-content: center;
	 display: flex;
	 margin: 5px;
	 color: rgb(247, 34, 34);
	 font-weight: 500;
 }
 
 .fade {
	 -webkit-animation: fade-in-keyframes 0.5s linear;
	         animation: fade-in-keyframes 0.5s linear;
 }
 @-webkit-keyframes fade-in-keyframes {
	 from {opacity: 0; height:0em}
	 to {opacity: 1;height:1em}
 }
 @keyframes fade-in-keyframes {
	 from {opacity: 0; height:0em}
	 to {opacity: 1;height:1em}
 }
 
 .closeWidth {
	 flex-basis:0px;
	 width: 5px;
	 -webkit-animation: fade-to0-width 0.3s ease-in-out;
	         animation: fade-to0-width 0.3s ease-in-out;
 }
 @-webkit-keyframes fade-to0-width {
	 from {flex-basis:200px; width: 200px}
	 to {flex-basis:0px; width: 0px}
 }
 @keyframes fade-to0-width {
	 from {flex-basis:200px; width: 200px}
	 to {flex-basis:0px; width: 0px}
 }
 
 .openWidth {
	 flex-basis:250px;
	 -webkit-animation: fade-to200-width 0.3s ease-in-out;
	         animation: fade-to200-width 0.3s ease-in-out;
 }
 @-webkit-keyframes fade-to200-width {
	 from {flex-basis:0px; width: 0px}
	 to {flex-basis:250px; width: 250px}
 }
 @keyframes fade-to200-width {
	 from {flex-basis:0px; width: 0px}
	 to {flex-basis:250px; width: 250px}
 }
 
 


.f {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;	
	background-image: url("/img/bg.jpg");
	position: absolute !important;
}

.login_box {
	display: flex;
	flex-direction: column;
	z-index: 100;
	min-width: 500px;
	padding: 10px;
	border-radius: 20px;
	box-shadow: 0px 0px 7px 2px #b7b7bb;
	background-color: rgba(218, 218, 218, 0.78);
	justify-content: center;
	align-items: center;
}

.login_fields {
	margin-top:10px;
}

#logo {
	width: 90%;
	max-width: 500px;
	padding:10px;
}

.fieldname {
	font-weight: 600;
	padding-right: 20px;
}

.login_input {
	border-radius: 8px;
	border-width: 1px;
	border-style: inset;
	background: rgb(246, 255, 255);
	padding-top:5px;
	padding-bottom: 3px;
	padding-left:10px;
	font-family: "Lucida Console", Monaco, monospace	;

}

.field_line {
	padding:3px;
	justify-content: space-between;
	display:flex;
}

.login_button {
	flex: 1 1;
	padding: 8px;
	margin: 6px;
	border-radius: 10px;
	font-size: 1em;
	margin-top: 10px;
	border-style: outset;
	background: #baea94;	
	font-weight: 600;
	border-width: 2px;
	color: rgb(1, 25, 41);
	font-family: 'IBM Plex Sans', sans-serif;
}

.title {
	font-family: 'IBM Plex Sans', sans-serif;
	font-size: 1.4em;
	font-weight: 600;
	color: hsl(206, 59%, 42%);
	margin-bottom: 10px;
}
.f {
	display: flex;
	flex-direction: column;
	background-size: cover;	
	background-image: url("/img/bg.jpg");
}

.topbar {
	background: rgba(0, 3, 9, 0.76);
	padding-top: 5px;
	padding-bottom: 5px;
	min-height: 50px;
	width: 100%;
	display:flex;
	align-items: center;
	justify-content: space-between;
}

.userInfo {
	flex-direction: column;
	display: flex;
	justify-content: center;
	font-family: 'Roboto Mono';
	flex:1 1;
}

.body {
	background: #dcdaffba;
	flex-grow: 1;	
	width: 100%;
}

.categoria {
	color: rgb(171, 195, 232);
	font-weight: 500;
}

.userFld {
	color: rgb(230, 235, 255);
	font-weight: 400;
}

.headerTitle {
	flex-direction: column;
	display: flex;
	justify-content: center;
}

.logoutBtn {
	background: rgb(243, 131, 106);
	color: white;
	font-family: 'Roboto';
	font-size: smaller;
	border-width: thin;
	border-radius: 10px;
	padding: 8px;
	margin-right: 20px;
}

.ml {
	margin-left:20px;
	flex:2 1;
}

.body {
	flex-direction: row;
	display: flex;
}

.sidebar {
	background: rgba(64, 97, 99, 0.76);
}

.content {
	flex:1 1;
	background: rgba(249, 249, 249, 0.59);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.toggleSidebar{
	left: 100%;
	position: relative;
	width: 30px;
	height: 50px;
	border-width: 0px;
	background: rgb(59, 210, 126);
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

@media screen and (max-width: 900px) {
  .userInfo {
		font-size: 0.6em;
	}
}

.sidebarBtn {
	margin: 5px;
    padding: 9px;
    background: rgb(201, 212, 248);
    border-radius: 4px;
    padding-left: 10px;
    font-family: 'Roboto';
    min-height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    border: black;
    border-width: 0px;
    border-style: solid;
    font-size: 0.98em;
}

.mapIcon {
	width: 30px;
}
.mapIconLabel{
	width: 100px;
	background: rgb(219, 221, 239);
	padding: 6px;
	border-radius: 15px;
	border-style: solid;
	border-width: 1px;
}

.leyenda {
	position: relative;
    float: left;
    z-index: 9999;
    height: 36px;
    width: 80px;
    background: white;
    border-radius: 10px;
    border-style: solid;
    border-width: thin;
    padding: 10px;
    font-size: x-small;
}
.tbl-content{
  height:80%px;
  overflow-x:auto;
  margin-top: 0px;
  border: 1px solid rgba(255,255,255,0.3);
}
th{
  padding: 20px 15px;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
}
td{
  padding: 15px;
  text-align: left;
  vertical-align:middle;
  font-weight: 300;
  font-size: 12px;
  color: #000;
  border-bottom: solid 1px rgba(255,255,255,0.1);
}


/* demo styles */
body{
  background: -webkit-linear-gradient(left, #25c481, #25b7c4);
  background: -webkit-gradient(linear, left top, right top, from(#25c481), to(#25b7c4));
  background: linear-gradient(to right, #25c481, #25b7c4);
  font-family: 'Roboto', sans-serif;
}
section{
  margin: 50px;
}




/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
    width: 6px;
} 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
} 
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
h1 {
	text-align: center;
	font-weight: 200;
}

.sendBtn {
	padding: 8px;
    margin: 6px;
    border-radius: 10px;
    font-size: 1em;
    margin-top: 10px;
    border-style: outset;
    background: #baea94;
    font-weight: 600;
    border-width: 2px;
    color: rgb(1, 25, 41);
    font-family: 'IBM Plex Sans', sans-serif;
}

.resetBtn {
	padding: 8px;
    margin: 6px;
    border-radius: 10px;
    font-size: 1em;
    margin-top: 10px;
    border-style: outset;
    background: aliceblue;
    font-weight: 600;
    border-width: 2px;
    color: rgb(1, 25, 41);
    font-family: 'IBM Plex Sans', sans-serif;
}

.cc {
	padding: 10px;
	font-size: 1.2em;
	width: 100px;
}
.cn {
	padding: 10px;
	font-size: 1.2em;
	width: 100px;
}

.campos {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.campos>label {
	padding:5px;
}
.campos>label>input {
	margin-left: 5px;
	border-radius: 4px;
	padding:5px;
}
