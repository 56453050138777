.f {
	display: flex;
	flex-direction: column;
	background-size: cover;	
	background-image: url("/img/bg.jpg");
}

.topbar {
	background: rgba(0, 3, 9, 0.76);
	padding-top: 5px;
	padding-bottom: 5px;
	min-height: 50px;
	width: 100%;
	display:flex;
	align-items: center;
	justify-content: space-between;
}

.userInfo {
	flex-direction: column;
	display: flex;
	justify-content: center;
	font-family: 'Roboto Mono';
	flex:1;
}

.body {
	background: #dcdaffba;
	flex-grow: 1;	
	width: 100%;
}

.categoria {
	color: rgb(171, 195, 232);
	font-weight: 500;
}

.userFld {
	color: rgb(230, 235, 255);
	font-weight: 400;
}

.headerTitle {
	flex-direction: column;
	display: flex;
	justify-content: center;
}

.logoutBtn {
	background: rgb(243, 131, 106);
	color: white;
	font-family: 'Roboto';
	font-size: smaller;
	border-width: thin;
	border-radius: 10px;
	padding: 8px;
	margin-right: 20px;
}

.ml {
	margin-left:20px;
	flex:2;
}

.body {
	flex-direction: row;
	display: flex;
}

.sidebar {
	background: rgba(64, 97, 99, 0.76);
}

.content {
	flex:1;
	background: rgba(249, 249, 249, 0.59);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.toggleSidebar{
	left: 100%;
	position: relative;
	width: 30px;
	height: 50px;
	border-width: 0px;
	background: rgb(59, 210, 126);
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

@media screen and (max-width: 900px) {
  .userInfo {
		font-size: 0.6em;
	}
}

.sidebarBtn {
	margin: 5px;
    padding: 9px;
    background: rgb(201, 212, 248);
    border-radius: 4px;
    padding-left: 10px;
    font-family: 'Roboto';
    min-height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    border: black;
    border-width: 0px;
    border-style: solid;
    font-size: 0.98em;
}

.mapIcon {
	width: 30px;
}
.mapIconLabel{
	width: 100px;
	background: rgb(219, 221, 239);
	padding: 6px;
	border-radius: 15px;
	border-style: solid;
	border-width: 1px;
}

.leyenda {
	position: relative;
    float: left;
    z-index: 9999;
    height: 36px;
    width: 80px;
    background: white;
    border-radius: 10px;
    border-style: solid;
    border-width: thin;
    padding: 10px;
    font-size: x-small;
}